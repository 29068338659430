@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html{
  box-sizing: border-box;
  width:100vw;
  height:100%;

}
body {
width:100%;
  color: white ;
  /* overflow: hidden; */
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

.contentEditablePlaceholder:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  opacity: 0.5;

  /* For Firefox */
}

.highlighted-e {
  /* background: red !important; */
  border-radius: 10px;
 color:#ff2454;
}

#emitter {
  visibility: visible;
  background-color: #222;
  position: absolute;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  top: 40%;
  left: 0%;
}

.dot-container {
  position: absolute;
  left: 0;
  top: 0;
  overflow: visible;
  z-index: 5000;
  pointer-events: none;
}

.dot {
  position: absolute;
  pointer-events: none;
  /*performance optimization*/
}

.balls {
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.balls div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #fc2f70;
  transform: translateY(-100%);
  animation: wave 0.8s ease-in-out alternate infinite;
}

.balls div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.balls div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes wave {
  from {
    transform: translateY(-50%);
  }

  to {
    transform: translateY(50%);
  }
}

@keyframes hover {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
.cardx{
  animation: hover 1s ease-in-out infinite;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  opacity: 0.2;
  /* For Firefox */
}

.example-exit {
  opacity: 0.01;
}

                .btn-grad {
                  background-image: linear-gradient(to right, #ff2454 0%, hsl(278, 100%, 30%) 51%, #ff2454 100%);
                  box-shadow: 0 0 7px 0px pink;
        
                }
           